<template>
  <a-card :bodyStyle="{ padding: 0 }">
    <template #title>
      <a-form :model="form" :labelCol="{ flex: '100px' }" layout="inline">
        <a-form-item label="">
          <a-input v-model:value="form.orderId" placeholder="订单号" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="form.vinNo" placeholder="车架号" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="form.orderStatus" allowClear placeholder="状态">
            <a-select-option v-for="item in orderStatusModes" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="form.orderSettlement" allowClear placeholder="结算方式">
            <a-select-option v-for="item in settlementModes" :key="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="form.customerName" placeholder="客户" />
          <!-- <a-select v-model:value="form.salesmanId" allowClear>
                <a-select-option v-for="item in settlementModes" :key="item" :value="item.value">{{item.label}}</a-select-option>
              </a-select> -->
        </a-form-item>
        <a-form-item label="">
          <a-range-picker v-model:value="form.timeLimit" style="width:100%" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD" :showTime="false" placeholder="推送时间" />
        </a-form-item>
        <a-form-item label="">
          <a-input-group compact>
            <a-input style="width: 50%" v-model:value="form.startAddress" placeholder="始发地">
              <template #prefix>始</template>
            </a-input>
            <a-input style="width: 50%" v-model:value="form.endAddress" placeholder="目的地">
              <template #prefix>终</template>
            </a-input>
          </a-input-group>
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="form.salesman" allowClear placeholder="业务员">
          </a-input>
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="form.officeOrgNames" style="width:140px" mode="multiple" placeholder="办事处">
            <a-select-option v-for="item in orgs" :key="item.name">{{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-space>
          <a-button type="primary" @click="searchData" :loading="loading">查询</a-button>
          <a-button @click="resetSearch">重置</a-button>
          <a-button danger @click="add">新增</a-button>
          <!-- <a-button type="link" @click="open = !open">{{ open ? '收起' : '展开' }}
            <UpOutlined v-if="open" />
            <DownOutlined v-else />
          </a-button> -->
        </a-space>
      </a-form>
    </template>
    <a-table :columns="[sortColumn, ...columns]" :row-key="record => record.id" :data-source="listData" :pagination="pagination" :loading="loading" @change="handleTableChange" :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)">
      <template #sort="{ index }">
        {{ index + 1 }}
      </template>
      <template #orderId="{ record }">
        <a-typography-paragraph :copyable="{ text: record.id }">
          {{ record.id }}
        </a-typography-paragraph>
      </template>
      <template #orderStatus="{ record }">
        <span v-if="record.orderStatus != null" :style="{ color: (record.orderStatus.value === 0) ? '#ff9900' : (record.orderStatus.value === 1) ? '#339933' : (record.orderStatus.value === 2) ? '#36a3e7' : (record.orderStatus.value === 9) ? '#19be6b' : '' }">
          {{ record.orderStatus.label }}</span>
      </template>
      <template #operation="{ record }">
        <span>
          <a @click="detail(record.id)">详情</a>
          <a-divider type="vertical" />
          <a @click="vehicleDetail(record)">跟踪</a>
          <a-divider type="vertical" />
          <div>
            <a-popconfirm title="确定要接单吗？" ok-text="确定" cancel-text="取消" @confirm="pickOrder(record)">
              <a v-show="record.salesman === null || record.salesman === ''">接单</a>
            </a-popconfirm>

            <a-divider v-show="record.salesman === null || record.salesman === ''" type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click.prevent v-if="record.orderStatus.value !== 9">
                更多
                <DownOutlined />
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-if="record.orderStatus.value === 5">
                    <a-popconfirm title="确定推送? " @confirm="send(record)" style="width:300px;">
                      <a>推送</a>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item v-if="!record.isPush.value">
                    <a @click="edit(record)">修改</a>
                  </a-menu-item>
                  <a-menu-item v-if="!record.isPush.value">
                    <a-popconfirm title="确定删除?" @confirm="deleteItem(record.id)">
                      <a>删除</a>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item v-if="record.insurance === 1">
                    <a @click="seeInsurance(record.id)">保险</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </span>
      </template>
      <!--      <template #expandedRowRender="{ record }">-->
      <!--        <VehicleTable :orderId="record.id" />-->
      <!--      </template>-->
    </a-table>
  </a-card>
</template>
<script>
// import { list as orgList } from '@/api/system/organzition'
import { receivePage, push, del, receive } from '@/api/inquiry/orderList'
import { useStore } from 'vuex'
import { getOrgList } from '@/utils/util'

import { toRefs, reactive, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { UpOutlined, DownOutlined } from '@ant-design/icons-vue'
import { message, Form } from 'ant-design-vue'
// import VehicleTable from './VehicleTable.vue'
export default {
  name: 'internal',
  setup () {
    const router = useRouter()
    const store = useStore()
    const form = reactive({
      orderId: '',
      vinNo: '',
      orderSettlement: null,
      orderStatus: null,
      salesmanId: '',
      salesman: "",
      customerName: "",
      timeLimit: [],
      startAddress: '',
      endAddress: '',
      officeOrgIds: [],
      officeOrgNames: []
    })

    const data = reactive({
      insurances: null,
      open: false,
      loading: false,
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      },
      sortColumn: { title: '序号', slots: { customRender: 'sort' } },
      columns: [
        { title: '订单号', dataIndex: 'orderId', slots: { customRender: 'orderId' } },
        { title: '客户名称', dataIndex: 'customerName' },
        { title: '始发地', dataIndex: 'startAddress' },
        { title: '目的地', dataIndex: 'endAddress' },
        { title: '所属区域', dataIndex: 'officeOrgName' },
        { title: '结算方式', dataIndex: 'orderSettlement.label' },
        { title: '创建时间', dataIndex: 'orderTime' },
        { title: '业务员', dataIndex: 'salesman' },
        { title: '状态', dataIndex: 'orderStatus', slots: { customRender: 'orderStatus' } },
        { title: '操作', dataIndex: 'operation', width: 200, slots: { customRender: 'operation' } }
      ],
      orgs: [],
      listData: [],
      orderStatusModes: [
        { label: '保存', value: 0 },
        { label: '已推送', value: 1 },
        { label: '处理中', value: 2 },
        { label: '完成', value: 9 }
      ],
      settlementModes: [
        { label: '货到付款', value: 1 },
        { label: '起运地付清', value: 2 },
        { label: '预付X到付X', value: 3 },
        { label: '月结', value: 4 },
        { label: '记账', value: 5 }
      ],
      vehicleColumns: [],
      vehicleData: []
    })
    const useForm = Form.useForm
    const { resetFields } = useForm(form, ref({}))

    const loadData = () => {
      data.loading = true
      form.officeOrgIds = []
      if (form.officeOrgNames && form.officeOrgNames.length > 0) {
        // 先支持中文搜索，这里转换为id
        form.officeOrgNames.map(n => {
          form.officeOrgIds.push(data.orgs.filter(item => item.name === n).map(item => item.id)[0])
        })
      }
      receivePage({
        ...form,
        pushStartTime: form.timeLimit[0],
        pushEndTime: form.timeLimit[1],
        current: data.pagination.current,
        size: data.pagination.pageSize
      }).then(res => {
        // console.log(res)  // 主要动态数据
        if (res.code === 10000) {
          data.listData = res.data.records
          data.pagination.current = res.data.current
          data.pagination.pageSize = res.data.size
          data.pagination.total = res.data.total
        }
        data.loading = false
      })
    }
    onMounted(async () => {
      data.orgs = store.state.app.orgTypeList
      if (data.orgs.length === 0 || data.orgs === null || data.orgs === undefined || data.orgs.length <= 0) {
        data.orgs = await getOrgList(1)
        // orgList({ orgType: 1 }).then(res => {
        //   state.orgs = res.data
        // })
      }
      loadData()
    })

    const handleTableChange = (pag, filters, sorter) => {
      data.pagination.current = pag.current
      data.pagination.pageSize = pag.pageSize
      loadData()
    }

    const searchData = () => {
      data.pagination.current = 1
      loadData()
    }

    const handleExpandVehicle = (expanded, record) => { }

    const resetSearch = () => {
      resetFields()
      loadData()
    }

    const add = () => {
      router.push({
        name: 'orderAdd',
        query: { backRoute: router.currentRoute.value.name }
      })
    }

    const seeInsurance = (id) => {
      data.insurances.insuranceDetail(id, true)
    }

    const edit = item => {
      router.push({
        name: 'orderEdit',
        params: { id: item.id, type: 0 },
        query: { backRoute: router.currentRoute.value.name }
      })
    }

    const pushEdit = item => {
      router.push({
        name: 'orderEdit',
        params: { id: item.id, type: 1 },
        query: { backRoute: router.currentRoute.value.name }
      })
    }

    const detail = id => {
      router.push({
        name: 'orderDetail',
        params: {
          id: id
        }
      })
    }

    const vehicleDetail = record => {
      router.push({
        name: 'vehicleDetail',
        params: {
          orderId: record.id,
          orderStatus: record.orderStatus.value
        }
      })
    }

    const pickOrder = record => {
      if (record.orderStatus.value !== 6) {
        receive(record.id)
          .then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              loadData()
            } else message.error(res.msg)
          }).catch(err => console.log(err))
      } else message.error('订单已拒绝')
    }

    const send = item => {
      push(item.id).then(res => {
        if (res.code === 10000) {
          message.success('推送成功')
          loadData()
        }
      })
    }

    const deleteItem = id => {
      del(id).then(res => {
        if (res.code === 10000) {
          message.success('删除成功')
          loadData()
        }
      })
    }

    return {
      form,
      ...toRefs(data),
      handleTableChange,
      searchData,
      resetSearch,
      handleExpandVehicle,
      add,
      edit,
      pushEdit,
      send,
      pickOrder,
      deleteItem,
      detail,
      loadData,
      vehicleDetail,
      seeInsurance
    }
  },
  components: {
    UpOutlined,
    DownOutlined
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
</style>
