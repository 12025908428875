import {
  postBodyRequest
} from '@/utils/axios'

export function receivePage (params) {
  return postBodyRequest('/trans/inquiry/receive/page', params)
}

export function push (id, params) {
  return postBodyRequest(`/trans/order/${id}/push`, params)
}

export function del (id) {
  return postBodyRequest(`/trans/order/${id}/delete`)
}

export function receive (orderId, params) {
  return postBodyRequest(`/trans/inquiry/receive/${orderId}`, params)
}
